$blue: #0f293d;
$white: #FFFFFF;
$orange: #cd8110;
$red: #ce2e2b;
$brown: #6c4b2c;

$xs-min: 320px;
$sm-min: 768px;
$md-min: 992px;
$lg-min: 1200px;

$xs-max: ($sm-min - 1);
$sm-max: ($md-min - 1);
$md-max: ($lg-min - 1);

$text-default: 1em;
$text-small: 0.8em;
$text-large: 1.2em;

$helvetica: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
$chaparral: "chaparral-pro", Georgia, serif;
$arial: Arial,sans-serif;
$courier: "Courier New",Courier,monospace;
$times: "Times New Roman",Times,serif;
$georgia: Georgia,serif;


// .serif {
//   font-family: 'Rokkitt', serif;
// }

.sans-serif {
  font-family: 'Raleway', sans-serif;
}

// @mixin serif {
//   font-family: 'Rokkitt', serif;
// }

@mixin sans-serif {
  font-family: 'Raleway', sans-serif;
}

$font-weight-lighter: 200;

$border-radius-sm: 3px;
$border-radius-base: 5px;
$border-radius-lg: 7px;

/* Effects */
@mixin box-shadow($shadow: 0px 0px 0px 5px rgba(0, 0, 0, .2)){
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin text-shadow($color: $white, $style: 0 1px 0){
  text-shadow: $style $color;
  //filter: dropshadow(color=$color, offx=0, offy=1);
}

@mixin border-radius($radius: 5px){
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin background-size($size: cover){
  -webkit-background-size: $size;
  -moz-background-size: $size;
  background-size: $size;
}

@mixin box-sizing($size: border-box){
  -webkit-box-sizing: $size;
  -moz-box-sizing: $size;
  box-sizing: $size;
  -ms-box-sizing: $size;
}

@mixin background-clip($clip: padding-box){
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin vertical-gradient($color1: rgba(255,255,255,0.5), $color2: rgba(255,255,255,0.5), $gradTop: 100%, $gradBot: 0%){
  background: $color1;
  background: -moz-linear-gradient(top,  $color1 $gradBot, $color2 $gradTop);
  background: -webkit-gradient(linear, left top, left bottom, color-stop($gradBot,$color1), color-stop($gradTop,#f7f7f7));
  background: -webkit-linear-gradient(top,  $color1 $gradBot,$color2 $gradTop);
  background: -o-linear-gradient(top,  $color1 $gradBot,$color2 $gradTop);
  background: -ms-linear-gradient(top,  $color1 $gradBot,$color2 $gradTop);
  background: linear-gradient(to bottom,  $color1 $gradBot,$color2 $gradTop);
  // filter: progid:DXImageTransform.Microsoft@include gradient( startColorstr='${color1}', endColorstr='${color2}',GradientType=0 );
}

@mixin diagonal-gradient(){
  background: -moz-linear-gradient(-45deg,  rgba(189,235,239,0.2) 0%, rgba(237,120,180,0.12) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(189,235,239,0.2)), color-stop(100%,rgba(237,120,180,0.12))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(-45deg,  rgba(189,235,239,0.2) 0%,rgba(237,120,180,0.12) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(-45deg,  rgba(189,235,239,0.2) 0%,rgba(237,120,180,0.12) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(-45deg,  rgba(189,235,239,0.2) 0%,rgba(237,120,180,0.12) 100%); /* IE10+ */
  background: linear-gradient(135deg,  rgba(189,235,239,0.2) 0%,rgba(237,120,180,0.12) 100%); /* W3C */
  // filter: progid:DXImageTransform.Microsoft@include gradient( startColorstr='#33bdebef', endColorstr='#1fed78b4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin transition($trans_property: all, $trans_duration: 0.3s, $tran_timing_function: ease){
  -webkit-transition: $trans_property $trans_duration $tran_timing_function;
  -moz-transition: $trans_property $trans_duration $tran_timing_function;
  -o-transition: $trans_property $trans_duration $tran_timing_function;
  transition: $trans_property $trans_duration $tran_timing_function;
}

@mixin rotate($degrees: 180deg){
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin transform($string){
  -webkit-transform: $string;
  -moz-transform:    $string;
  -ms-transform:     $string;
  -o-transform:      $string;
  transform:         $string;
}

@mixin scale($factor){
  -webkit-transform: scale($factor);
  -moz-transform:    scale($factor);
  -ms-transform:     scale($factor);
  -o-transform:      scale($factor);
}

@mixin skew($deg, $deg2){
  -webkit-transform:       skew($deg, $deg2);
  -moz-transform:    skew($deg, $deg2);
  -ms-transform:     skew($deg, $deg2);
  -o-transform:      skew($deg, $deg2);
}

@mixin translate($x, $y:0){
  -webkit-transform:       translate($x, $y);
  -moz-transform:    translate($x, $y);
  -ms-transform:     translate($x, $y);
  -o-transform:      translate($x, $y);
}

@mixin translate3d($x, $y: 0, $z: 0){
  -webkit-transform:       translate3d($x, $y, $z);
  -moz-transform:    translate3d($x, $y, $z);
  -ms-transform:     translate3d($x, $y, $z);
  -o-transform:      translate3d($x, $y, $z);
}

@mixin perspective($value: 1000){
  -webkit-perspective:  $value;
  -moz-perspective:   $value;
  -ms-perspective:  $value;
  perspective:    $value;
}

@mixin transform-origin($x:center, $y:center){
  -webkit-transform-origin: $x $y;
  -moz-transform-origin:    $x $y;
  -ms-transform-origin:     $x $y;
  -o-transform-origin:      $x $y;
}

@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease){
  -webkit-animation: $name $duration $delay $ease;
  -moz-animation:    $name $duration $delay $ease;
  -ms-animation:     $name $duration $delay $ease;
}

@mixin opacity($value: 0.2){
    /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  /* IE 5-7 */
  filter: alpha(opacity=50);
  /* Netscape */
  -moz-opacity: $value;
  /* Safari 1.x */
  -khtml-opacity: $value;
  /* Good browsers */
  opacity: $value;
}

/* Utilities */
.block { display: block; }
.inline-block { display: inline-block; }
.inline{display: inline;}
.absolute { position: absolute !important; }
.relative { position: relative; }
.fixed { position: fixed; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.large-text { font-size: $text-large; }
.small-text { font-size: $text-small; }
.italic { font-style: italic !important; }
.normal { font-weight: normal !important; }
.bold { font-weight: bold; }
.no-border { border: 0 !important; }
.light-border { border: solid 1px #ccc; }
.bottom-border { border-bottom: solid 1px #ccc; }
.top-border { border-top: solid 1px #ccc; }
.no-bottom-margin { margin-bottom: 0 !important; }
.no-padding { padding: 0 !important; }
.no-bottom-padding { padding-bottom: 0 !important; }
.no-top-padding { padding-top: 0 !important; }
.gimme-a-lil-padding { padding: 0 10px; }
.pull-right { float: right; }
.pull-left { float: left; }
.float-none { float: none !important; }
.disabled { opacity: 0.5; cursor: default !important; }
.underline { text-decoration: underline; }
.clear-both { clear: both; }
.clear-left { clear: left; }
.clear-right { clear: right; }
.margin-top-10 { margin-top: 10px; }
.margin-top-20 { margin-top: 20px; }
.margin-top-30 { margin-top: 30px; }
.margin-bottom-10 { margin-bottom: 10px; }
.margin-bottom-20 { margin-bottom: 20px; }
.margin-bottom-30 { margin-bottom: 30px; }
.padding-top-10 { padding-top: 10px; }
.padding-top-20 { padding-top: 20px; }
.padding-top-30 { padding-top: 30px; }
.padding-bottom-10 { padding-bottom: 10px; }
.padding-bottom-20 { padding-bottom: 20px; }
.padding-bottom-30 { padding-bottom: 30px; }
.padding-bottom-100 { padding-bottom: 100px; }
.text-action{cursor:pointer;}
.valign-middle{vertical-align:middle}

.indent { padding-left: 20px; }
.indent-right { padding-right: 20px; }

.capitalize { text-transform: capitalize; }

.font-weight-lighter { font-weight: $font-weight-lighter; }