@import url(http://fonts.googleapis.com/css?family=Rokkitt|Raleway:100,300);
@import "variables.scss";

// Rando
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}


// Overrides
html, body {
  margin: 0;
  padding: 0;
  font-size: 1em;
  position: relative;  
  @include sans-serif;
  @media(min-width: $md-min) {
    font-size: 1.2em;
  }
  @media(min-width: $lg-min) {
    font-size: 1.4em;
  }
}


// p, li, input, textarea {
//   font-size: 1em;
//   @media(min-width: $sm-min) {
//     font-size: 1.2em;
//   }
//   @media(min-width: $md-min) {
//     font-size: 1.4em;
//   }
//   // @media(min-width: $lg-min) {
//   //   font-size: 1.6em;
//   // }
// }

// h2 {
//   font-size: 2rem;
//   // @media(min-width: $sm-min) {
//   //   font-size: 1.9em;
//   // }
//   // @media(min-width: $md-min) {
//   //   font-size: 2.1em;
//   // }
//   // @media(min-width: $lg-min) {
//   //   font-size: 2.4em;
//   // }
// }

// h4 {
//   font-size: 1.2em;
//   @media(min-width: $sm-min) {
//     font-size: 1.4em;
//   }
//   @media(min-width: $md-min) {
//     font-size: 1.6em;
//   }
//   @media(min-width: $lg-min) {
//     font-size: 1.8em;
//   }
// }

input.text-danger,
textarea.text-danger {
  border-color: $red;
}

label.text-danger { 
  padding-left: 17px; 
  color: $red;
}

.container .jumbotron {
  margin-top: 0;
  margin-bottom: 0;
}

// Styles
#welcometron {
  background: transparent;
  color: white;
  opacity: 1;
  h1 {
    font-size: 2rem;
    @media(min-width: $sm-min) {
      font-size: 3.5rem;
    }
    @media(min-width: $md-min) {
      font-size: 4rem;
    }
    @media(min-width: $lg-min) {
      font-size: 5rem;
    }
  }
  p {
    font-weight: 300;
    font-size: 1rem;
    @include text-shadow(rgba(0,0,0,0.2));
    @media(min-width: $sm-min) {
      font-size: 1.5rem;
    }
    @media(min-width: $md-min) {
      font-size: 1.7rem;
    }
  }
  .btn {
    font-size: 14px;
    @include text-shadow(rgba(0,0,0,0.2));
    background: $orange;
    border-color: $orange;
    color: $white;
    padding: 1rem 1.5rem;
    @media(min-width: $sm-min) {
      font-size: 1.5rem;
    }
    @media(min-width: $md-min) {
      font-size: 1.7rem;
    }
  }
  .btn:hover,
  .btn:focus,
  .btn:active,
  .btn.active {
    background: darken($orange, 5%);
  }

}

.jumbo-text {
  font-size: 5em;
}

.jumbotron {
  margin: 100px 0;
  //background: rgba(255,255,255,0.6);
  background: transparent;
  color: $white;
  @include text-shadow(rgba(0,0,0,1));
}

#welcome-text {
  @include text-shadow();
}

.banner {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  @include box-shadow(0px 0px 0px 5px fade($blue, 30%));  
  @media (min-width: $sm-min) {
    // height: 100vh;
  }
}

.banner:last-child {
  margin-bottom: 100px;
}

.banner-inner {
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  @media (min-width: $sm-min) {
    //height: 100vh;
  }  
}

#home {
  background: url(/img/banners/lighthouse-2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size();
  .banner-inner {
    background: rgba(0,0,0,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.6);
  }
}

#about {
  background: url(/img/banners/chimney.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  @include background-size();  
  .banner-inner {
    border-top: 1px solid rgba(0,0,0,0.2);
  }
  .jumbotron {
    background-color: #0a1b29;
    color: #FFFFFF;
    margin-top: 2em;
    @media (min-width: $sm-min) {
      margin-top: 4em;
    }
  }
  .jumbotron h2 {
    // font-size: 2em;
    // @media(min-width: $sm-min) {
    //   font-size: 2.5em;
    // }
    // @media(min-width: $md-min) {
    //   font-size: 3em;
    // }
    // @media(min-width: $lg-min) {
    //   font-size: 4em;
    // }
  }
  .jumbotron p {
    // font-size: 1em;
    // @include text-shadow(rgba(0,0,0,0.2));
    // @media(min-width: $sm-min) {
    //   font-size: 1.2em;
    // }
    // // @media(min-width: $md-min) {
    //   font-size: 1.5em;
    // }
  }
}

#services {
  background: white;
}

#services img {
  max-height: 40vh;
  width: 100%;
}


#contact {
  //background: url(/img/banners/contact-6.jpg);
  //background-repeat: no-repeat;
  //background-position: center center;
  background-color: lighten($blue, 20%);
  color: $white;
  @include text-shadow(rgba(0,0,0,0.2));
  @include background-size();
  hr {
    border-color: rgba(255,255,255,0.4);
  }
}

#contact .banner-inner {
  background: rgba(0,0,0,0.4);
}

#footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
  width: 100%;
  color: rgba(255,255,255,0.4);
}

#mc-navbar {
  background-color: $blue;
  @include box-shadow(0px 0px 0px 5px fade($blue, 30%));
  border: 0;
  color: $white;
  padding: 0;
  a {
    color: $white;
    font-weight: 200;
    font-size: 20px;
    line-height: 26px;
    height: 44px;
    display: block;
    // @include serif();
    @include border-radius(4px);
    @include transition();
    margin: 8px;
    padding: 12px;
    // @media (min-width: $sm-min) {
    //   line-height: 18px;
    //   margin: 10px 8px 10px 8px;
    // }
  }
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
    background: darken($blue, 10%);
    // @media (min-width: $sm-min) {
    //   background: darken($blue, 5%);
    // }
  }
  .active > a {
    background: $orange;
    // @media (min-width: $sm-min) {
    //   background: darken($blue, 5%);
    // }    
  }
  .navbar-toggler,
  .navbar-toggler:active,
  .navbar-toggler:focus {
    @include border-radius(0);
    // height: 44px;
    // width: 44px;
    background-color: transparent;
    .icon-bar {
      background-color: rgba(255,255,255,0.8);
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
    }
  }
  .navbar-toggler:hover {
    background-color: $orange;
    border-color: $orange;  
  }
  .navbar-toggler {
    margin-top: 11px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .navbar-brand {
    margin-top: 8px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .navbar-header {
    margin-left: 0;
  }
  .navbar-brand {
    padding: 0;
    @media (min-width: $sm-min) {
      // margin-left: 15px;
    }
  }
  .navbar-collapse {
    border: 0;    
    text-align: left;
    background-color: darken($blue, 5%) !important; 
    @media (min-width: $sm-min) {
      text-align: center;
      background-color: transparent;
    }
    &.show {
      a {
        height: auto;
        display: block;
      }
    }
    ul {
      margin: 0 auto;
      display: block;
      @media (min-width: $sm-min) {
        display: inline-block;
        float: none;
      }      
    }
    // .navbar-brand-wrapper {
    //   margin: -100px 0 -50px;
    //   height: 200px;
    //   width: 200px;
    //   overflow: visible;
    //   background: darken($blue, 2%);
    //   @include box-shadow();
    //   @include border-radius(300px);
    //   text-align: center;
    //   img {
    //     margin-top: 120px;
    //     margin-left: auto;
    //     margin-right: auto;
    //     display: inline-block;
    //     height: 80px;
    //   }
    .navbar-brand-wrapper {
      margin: -10px 20px -30px;
      height: 100px;
      width: 200px;
      overflow: visible;
      background: darken($blue, 2%);
      @include box-shadow();
      text-align: center;
      img {
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
        height: 60px;
        margin-top: 26px;
      }
    }
  }
  // #main-logo-wrapper {
  //   width: 200px;
  //   height: 100%;
  //   position: relative;
  // }
  // #main-logo {
  //   width: 200px;
  //   display: block;
  //   height: 200px;
  //   position: absolute;
  //   top: -100px;
  //   @include border-radius(200px);
  // }
}

span.title {
  font-size: 0.9rem;
  color: rgba(255,255,255,0.7);
}

#centered-logo {
  // margin-top:20px;
  @media (max-width: $sm-max) {
    width: 120px;
  }
}